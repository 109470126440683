.master-sub-container{
    padding-top: 5%;
    padding-right: 15%;
    padding-left: 6% ;
    font-size: 15px;
}

.master-sub-container strong{
    font-weight: 900;
}
.master-sub-container p{
    color: #666666;
    line-height: 28px;
    margin: 0 0 15px;
    text-transform: none;
    font-weight: 400;
}
.master-sub-container a{
    text-decoration: none;
    color: #232323;
    font-weight: 700;
}
.master-sub-container a:hover{
    color: #005AD2;
}

.master-sub-container ul{
    margin: 0;
    list-style-type: none;
    box-sizing: border-box;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    overflow-x: hidden;
    background-color: #ffffff;
    color: #666666;
}
.master-sub-container li{
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

.master-sub-container ol{
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    background-color: #ffffff;
    color: #666666;
}

.master-sub-container table{
    width: 100%;
    margin-bottom: 20px;
    vertical-align: top;
    overflow: hidden;
    border: 2px solid rgba(0, 0, 0, 0.08);
    border-collapse: collapse;
    display: table;
    text-indent: initial;
    unicode-bidi: isolate;
    border-spacing: 2px;
}

.master-sub-container table thead{
    display: table-header-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

.master-sub-container table thead tr{
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}
.master-sub-container table thead tr th{
    padding: 10px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    border-top: 2px solid rgba(0, 0, 0, 0.08);
    border-left: 2px solid rgba(0, 0, 0, 0.08);
    border-right: 2px solid rgba(0, 0, 0, 0.08);
    color: #666666;
    font-size: 1.2rem;
    font-weight: 700;
}

.master-sub-container table tbody{
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

.master-sub-container table td{
    padding: 10px;
    vertical-align: top;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    border-top: 2px solid rgba(0, 0, 0, 0.08);
    border-left: 2px solid rgba(0, 0, 0, 0.08);
    border-right: 2px solid rgba(0, 0, 0, 0.08);
}