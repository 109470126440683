.terms-and-privacy-container{
    display: flex;
    flex-direction: column;
    padding-left: 20rem;
    margin-top: 5rem;
    gap: 3rem;
    color: #232323;
}

.terms-and-privacy h1{
    font-size: 3rem;
    font-weight: 400;
    margin: 0;
}
.terms-and-privacy p{
    font-size: 1.5rem;
    margin: 0;
}

.terms-and-conditions-container{
    display: flex;
}
.terms-and-conditions{
    width: 50%;
}
.terms-and-conditions h2{
    font-size: 2rem;
    font-weight: 400;
}
.terms-and-conditions a{
    text-decoration: none;
    color: #232323;
    font-weight: 700;
}
.terms-and-conditions hr{
    opacity: 0.4;
    width: 80%;
    margin-left: 0;
}

.terms-and-conditions a:hover{
    color: #005AD2;
}

.disabled{
    cursor: default;
    pointer-events: none;
    color: #666666 !important;
}

@media only screen and (max-width: 800px) {
    .terms-and-privacy-container{
        padding-left: 2rem;
    }
    .terms-and-conditions-container{
        flex-direction: column;
    }
    .terms-and-conditions{
        width: 100%;
    }
}