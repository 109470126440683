.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    
  }
  
  .chatbot-chat-icon {
    background-color: #005ad2;
    color: white;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  
  .chatbot-chat-window {
    width: 100%;
    max-width: 350px;
    height: 80vh;
    max-height: 500px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 70px;
    right: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .chatbot-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #005ad2;
    color: white;
    font-size: 1rem;
  }

  .chatbot-chat-header button{
    background-color: #005ad2;
    font-size: large;
    font-weight: 800;
  }
  
  .chatbot-chat-messages {
    overflow-y: auto;
    max-height: 400px;
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    font-size: 0.9rem;
  }
    /* Custom Scrollbar Styles */
    .chatbot-custom-scrollbar::-webkit-scrollbar {
        width: 5px; 
    }

    .chatbot-custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 5px; 
    }

    .chatbot-custom-scrollbar::-webkit-scrollbar-track {
        background-color: #f1f1f1; 
    }

    .chatbot-custom-scrollbar::-webkit-scrollbar-corner {
        background-color: transparent;
    }
  
  .chatbot-message {
    padding: 8px;
    margin: 5px 0;
    border-radius: 5px;
    font-size: 0.9rem;
    max-width: 80%;
  }
  
  .chatbot-message.user {
    background-color: #005ad2;
    color: white;
    align-self: flex-end;
    margin-left: auto;
    border-top-right-radius: 0;
  }
  
  .chatbot-message.bot {
    background-color: #f1f0f0;
    align-self: flex-start;
    margin-right: auto;
    border-top-left-radius: 0;
  }
  
  .chatbot-chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .chatbot-chat-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    font-family: "Nunito", sans-serif;
    color: rgba(0, 0, 0, 0.774);
    transition: border-color 0.3s ease;
  }

  .chatbot-chat-input input:focus {
    border-color: gray; 
    outline: none; 
  }
  
  .chatbot-send-button {
    background-color: #005ad2;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    width: 40px;
    height: 40px;
  }
  
  .chatbot-send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .chatbot-container {
      bottom: 15px;
      right: 15px;
    }
  
    .chatbot-chat-window {
      width: 90%;
      height: 75vh;
      bottom: 15px;
      right: 15px;
    }
  
    .chatbot-chat-header, .chatbot-chat-input button {
      font-size: 0.9rem;
    }
  
    .chatbot-chat-input input {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .chatbot-chat-window {
      width: 100%;
      height: 80vh;
      bottom: 0;
      right: 0;
      border-radius: 0;
    }
  
    .chatbot-chat-icon {
      font-size: 18px;
      padding: 8px;
    }
  
    .chatbot-chat-header, .chatbot-chat-input button {
      font-size: 0.85rem;
    }
  
    .chatbot-chat-input input {
      font-size: 0.85rem;
    }
  }
  