.reporting-tools-container {
  display: flex;
  flex-direction: column;
  padding-left: 20rem;
  margin-top: 2.5rem;
  color: #232323;
  gap: 1.5rem;
}

.reporting-tools h1 {
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
}

.forms-container a{
  margin-top: 1rem;
}

.reporting-tools {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.reporting-tools h2 {
  font-size: 2rem;
  font-weight: 400;
}

.reporting-tools a {
  text-decoration: none;
  color: #232323;
  font-weight: 700;
}

.reporting-tools hr {
  opacity: 0.4;
  width: 80%;
  margin-left: 0;
}

.reporting-tools a:hover {
  color: #005AD2;
}

.disabled {
  cursor: default;
  pointer-events: none;
  color: #666666 !important;
}

@media only screen and (max-width: 800px) {
  .reporting-tools-container {
    padding-left: 2rem;
  }

  .reporting-tools-container {
    flex-direction: column;
  }

  .reporting-tools {
    width: 100%;
  }
}