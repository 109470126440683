.footer-icons-container a{
  display: inline-block;
  height: 37px;
  width: 37px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  outline: none;
}

.facebook {
  background: #3B5998 none repeat scroll 0 0;
}

.twitter {
  background: #1DA1F2 none repeat scroll 0 0;
}

.instagram {
  background: #BD081C none repeat scroll 0 0;
}

.youtube {
  background: #cd201f none repeat scroll 0 0;
}