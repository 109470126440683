body {
  margin: 0px;
  box-sizing: border-box;
}
.container {
  background: #e2f1ffab;
  font-family: "Poppins", sans-serif;
}
.registry-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 4rem auto;
  gap: 2rem;
}

.what-is-registry {
  display: flex;
  gap: 2rem;
  box-sizing: border-box;
  height: 35vw;
}

.what-is-registry-left {
  width: 45%;
  background: white;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 4em;
  box-sizing: border-box;
}
.what-is-registry-left h2,
.what-is-registry-left h1,
.what-is-registry-left p {
  margin: 0;
  width: 100%;
}
.what-is-registry-left h2 {
  font-size: 3vw;
  color: #2d2e2f;
  width: 100%;
  font-weight: 500;
}
.what-is-registry-left h1 {
  font-size: 4.5vw;
  color: #3383f0;
  width: 100%;
  font-weight: 800;
  transform: scale(1, 1.1);
  letter-spacing: 3px;
  margin-bottom: 1rem;
}
.what-is-registry-left p {
  color: #5a5c61;
  font-size: 1vw;
  line-height: 1.6;
  width: 100%;
}
.what-is-registry-right {
  width: 55%;
  height: 100%;
  border-radius: 50px;
  overflow: hidden;
}
.what-is-registry-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.benefits-of-registry {
  border-radius: 50px;
  background: white;
  display: flex;
  box-sizing: border-box;
  height: 28vw;
}
.benefits-of-registry ul {
  padding: 0;
  width: 100%;
}
.benefits-of-registry li {
  display: flex;
  align-items: center;
  list-style-type: none;
  position: relative;
  color: #5a5c61;
  font-size: 1.1vw;
  letter-spacing: 1px;
}

.benefits-of-registry li::before {
  content: "•";
  color: #d6e6fc;
  font-size: 2.5vw;
  margin-right: 10px;
  position: relative;
}
.benefits-of-registry-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  width: 25%;
}
.benefits-of-registry-left h2 {
  margin: 0;
  font-size: 4.5vw;
  margin-top: -10px;
  color: #3383f0;
  font-weight: 500;
}
.benefits-of-registry-left h3 {
  font-size: 2.5vw;
  margin: 2px;
  color: #2d2e2f;
  font-weight: 500;
}
.benefits-of-registry-mid {
  display: flex;
  padding: 4rem 2rem;
  width: 40%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.benefits-of-registry-mid ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.benefits-of-registry-right {
  display: flex;
  padding: 4rem 0;
  width: 40%;
  justify-content: center;
  align-items: center;
  padding-right: 4rem;
  box-sizing: border-box;
}
.benefits-of-registry-right ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.needs-of-registry {
  display: flex;
  box-sizing: border-box;
  height: 22vw;
  gap: 2rem;
}
.needs-of-registry-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 4rem;
  width: 30%;
  background: white;
  border-radius: 50px;
}
.needs-of-registry-left h2 {
  margin: 0;
  font-size: 4.2vw;
  color: #3383f0;
  letter-spacing: 2px;
  font-weight: 500;
}
.needs-of-registry-left h3 {
  font-size: 2.5vw;
  margin: 0;
  color: #2d2e2f;
  font-weight: 500;
  letter-spacing: 1px;
}
.needs-of-registry-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4vw 4vw;
  width: 65%;
  background: white;
  border-radius: 50px;
  box-sizing: border-box;
}
.vision-mission {
  display: flex;
  gap: 2rem;
  box-sizing: border-box;
  height: 40vw;
}
.vision-mission-left {
  width: 60%;
  height: 100%;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vision-mission-left video {
  border-radius: 50px;
}
.vision-mission-right {
  width: 40%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.vision-mission-right-top {
  height: 40%;
  background: white;
  border-radius: 50px;
  padding: 2rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0;
}
.vision-mission-right h3 {
  font-size: 1.8vw;
  color: #3383f0;
  margin: 0;
  letter-spacing: 1px;
}
.vision-mission-right p {
  font-size: 1.12vw;
  color: #5a5c61;
  letter-spacing: 1px;
  line-height: 1.6;
  margin: 0;
}
.vision-mission-right-bottom {
  height: 60%;
  background: white;
  border-radius: 50px;
  padding: 2rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0;
}

.list-of-registries {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
}
.list-of-registries-title {
  font-size: 2rem;
  letter-spacing: 3px;
  color: #3383f0;
}
.list-of-registries-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 2rem 4rem;
  justify-content: center;
}
.registry-card-container {
  flex: 1 1 calc(33.33% - 20px);
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
}
.registry-card-container-top {
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.registry-card-container-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.registry-card {
  box-sizing: border-box;
  margin-bottom: 16px;
  height: 350px;
  width: 100%;
  border-radius: 0 0 20px 20px;
  display: flex;
  cursor: pointer;
  background: #ffffffcb;
}
.registry-card:hover {
  background-color: #ccedff;
  transition: background-color 0.3s ease;
}

.registry-card:hover .registry-card-left-box {
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}
.registry-card-left {
  padding: 1.5rem;
  padding-right: 0;
}
.registry-card-left-box {
  background: #daf2ff;
  width: 4vw;
  height: 4vw;
  border-radius: 20px;
  overflow: hidden;
  padding: 6px;
}
.registry-card-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.1vw;
  gap: 1rem;
}
.registry-card-right-top {
  font-size: 1.5vw;
  font-weight: 600;
  letter-spacing: 0.5px;
  height: 30%;
  display: flex;
  align-items: center;
}
.registry-card:hover .registry-card-right-top {
  color: #3383f0;
  transition: background-color 0.3s ease;
}
.registry-card-right-mid {
  font-size: 1vw;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.4;
  height: 60%;
  color: #5a5c61;
}
.registry-card-right-bottom {
  height: 10%;
  color: #3383f0;
  font-weight: 800;
  font-size: 1.1vw;
  text-decoration: underline;
  cursor: pointer;
}

.testimonials {
  display: flex;
  gap: 2rem;
  box-sizing: border-box;
  height: 38vw;
  background: #006eda;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
  padding: 8rem 8rem;
}

@media (max-width: 1200px) {
  .what-is-registry-left {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .what-is-registry {
    flex-direction: column;
    height: 100%;
  }

  .what-is-registry-left {
    width: 100%;
    height: 60vh;
    padding: 1vw 5vw;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .what-is-registry-left h2,
  .what-is-registry-left h1,
  .what-is-registry-left p {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .what-is-registry-left h2 {
    font-size: 1.8rem;
  }

  .what-is-registry-left h1 {
    font-size: 2.5rem;
    margin-top: -1rem;
  }

  .what-is-registry-left p {
    font-size: 1rem;
  }

  .what-is-registry-right {
    width: 100%;
  }

  .benefits-of-registry {
    height: auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .benefits-of-registry ul {
    padding: 0;
    width: 100%;
    gap: 1rem;
  }

  .benefits-of-registry li {
    font-size: 1.2rem;
    letter-spacing: 2px;
  }

  .benefits-of-registry li::before {
    font-size: 6vw;
  }

  .benefits-of-registry-left {
    align-items: center;
    width: 100%;
  }

  .benefits-of-registry-left h2 {
    font-size: 2.8rem;
  }

  .benefits-of-registry-left h3 {
    font-size: 1.8rem;
  }

  .benefits-of-registry-mid {
    width: 100%;
    padding: 0 2rem;
  }

  .benefits-of-registry-right {
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    padding-bottom: 2rem;
  }

  .needs-of-registry {
    height: auto;
    flex-direction: column;
  }

  .needs-of-registry-left {
    padding: 2rem 4rem;
    width: 100%;
    height: 25vh;
    box-sizing: border-box;
  }

  .needs-of-registry-left h2 {
    text-align: center;
    font-size: 10vw;
  }

  .needs-of-registry-left h3 {
    text-align: center;
    font-size: 5vw;
  }

  .needs-of-registry-right {
    padding: 6vw 6vw;
    width: 100%;
    height: 60vh;
  }
  .vision-mission {
    flex-direction: column;
    height: auto;
  }
  .vision-mission-left {
    width: 100%;
  }
  .vision-mission-right {
    width: 100%;
    height: auto;
  }
  .vision-mission-right-top {
    height: 40vh;
  }
  .vision-mission-right h3 {
    font-size: 1.5rem;
  }
  .vision-mission-right p {
    font-size: 1rem;
  }
  .vision-mission-right-bottom {
    height: 60%;
    background: white;
    border-radius: 50px;
    padding: 2rem 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
  }
  .list-of-registries-cards-container {
    padding: 1rem 1rem;
  }
  .list-of-registries-title {
    text-align: center;
  }
  .registry-card-container {
    height: 500px;
    flex: 1 1 100%;
  }
  .registry-card {
    flex: 1 1 100%;
    height: 400px;
  }
  .registry-card-left {
    padding: 1.5rem;
    padding-right: 0;
    padding-bottom: 2rem;
  }
  .registry-card-left-box {
    width: 10vw;
    height: 10vw;
    border-radius: 10px;
  }
  .registry-card-right {
    gap: 0;
    padding: 1rem;
  }
  .registry-card-right-top {
    font-size: 1.2rem;
    height: 20%;
  }
  .registry-card-right-mid {
    font-size: 0.8rem;
    height: 70%;
  }
  .registry-card-right-bottom {
    font-size: 0.8rem;
  }
  .testimonials {
    flex-direction: column;
    height: auto;
    padding: 2rem 4rem;
  }
}

/* 
.registries-container {
  & .evidence-info {
    background-color: royalblue;
    height: 20vh;
  }

  & .content {
    padding: 20px 30px;
    font-size: 2vw;
    color: white;
  }

  & .filter {
    justify-content: center;
    margin-left: 4%;
    display: flex;
  }
}

.registries-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more {
  text-decoration: none;
  color: #005ad2;
}

.registries-cards-container {
  width: 70%;
  margin: auto;
}

.registries-card {
  width: 260px;
  height: 400px;
  transition: all 0.2s ease-in-out !important;
}

@media only screen and (min-width: 900px) {
  .registries-card:hover {
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.registries-banner-container {
  position: relative;
  top: 0;
  left: 0;
}
.registries-banner {
  top: 0;
  left: 0;
  position: relative;
}

.registries-card-icon {
  position: absolute;
  bottom: -20px;
  left: 50px;
  background-color: white;
  font-size: 50px;
  padding: 5px;
  border-radius: 50%;
  color: royalblue;
}

@media only screen and (max-width: 700px) {
  .registries-container {
    & .registries-info {
      background-color: royalblue;
      height: 20vh;
    }

    & .content {
      padding: 30px;
      font-size: 5vw;
      color: white;
    }

    & .filter {
      justify-content: center;
      margin-left: 4%;
      display: flex;
    }
  }

  .registries-cards-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .registries-card {
    margin: auto;
    width: 80%;
    height: 400px;
  }
}
*/
