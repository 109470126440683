

@media only screen and (max-width: 768px) {
  video{
    width: 100%;
  }
  .clients-img{
    width: 80px;
    height: 30px;
  }
  .details-image{
    width: 90%;
  }
}