.coming-soon-container{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.coming-soon{
    font-size: 5rem;
    font-weight: 600;
    margin: 0;
    letter-spacing: 1rem;
    color: #005AD2;
}

.coming-soon-desc{
    font-size: 1.5rem;
}


@media only screen and (max-width: 800px) {
    .coming-soon{
        font-size: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .coming-soon-desc{
        text-align: center;
        font-size: 1.2rem;
    }
}