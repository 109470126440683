.careers-page {
  padding: 0 20px;
}

.jobs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin-bottom: 2rem;
}

.job-card {
  padding: 4px;
  cursor: pointer;
  width: 280px;
  box-sizing: border-box;
  height: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-bottom: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 6px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.job-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
              0 12px 40px rgba(0, 0, 0, 0.2);
}

.job-card-upper{
  background: #e2eeff;
  height: 80%;
  margin: 0;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
}

.job-card-upper p{
  text-align: center;
}



.job-card-lower{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  margin: 0 10px;
}
.job-card-lower .logo{
  display: flex;
  margin-left: -4px;
}

.job-card-lower .job-title{
  width: 54%;
  margin-left: -20px;
  color: black;
  font-size: 14px;
  font-weight: bold;
}
.job-card-lower .view-button button{
  background-color: #005AD2;
  border-radius: 20px;
  width: 60px;
}

button {
  background-color: #005AD2;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.hr-email {
  color: #005AD2;
}

.careers-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jobDetailContainer{
  color: #232323;
}