body{
    margin: 0px;
}
.evidence-container{
    & .evidence-info{
        background-color: royalblue;
        height: 20vh;
    }
    
    & .content{
        padding: 20px 30px;
        font-size: 2vw;
        color: white;
    }

    & .filter{
        justify-content: center ;
        margin-left: 4%;
        display: flex;
    }   
}

.evidence-title{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
}

.read-more{
    text-decoration: none;
    color: #005AD2;
}

.product-body{
    width: 70%;
    margin: auto;
}

.product-card{
    width: 260px;
    height: 350px;  
    transition: all .2s ease-in-out !important;  
}

@media only screen and (min-width: 900px) {

.product-card:hover{
    -ms-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);    
}
}

.banner-container{
    position: relative;
    top: 0;
    left: 0;
}
.banner{
    top: 0;
    left: 0;
    position: relative;
}

.evidence-card-icon{
    position: absolute;
    bottom:-20px;
    left:50px;
    background-color: white;
    font-size: 50px;
    padding: 5px;
    border-radius: 50%;
    color:royalblue;
}

@media only screen and (max-width: 700px) {

    .evidence-container{
        & .evidence-info{
            background-color: royalblue;
            height: 20vh;
        }
        
        & .content{
            padding: 30px;
            font-size: 5vw;
            color: white;
        }
    
        & .filter{
            justify-content: center ;
            margin-left: 4%;
            display: flex;
        }   
    }


    .product-body{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    
    .product-card{
        margin: auto;
        width: 80%;
        height: 350px;  
    }

}
