.carousel-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
.swiper-slide {
  position: relative;
  overflow: hidden;
}

.swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #f3f4f6, #e5e7eb);
  z-index: -1;
}

.slide-content {
  position: relative;
  z-index: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
.slide-content-top {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.slide-content-top-left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background: #d6e6fc;
  border-radius: 3px;
}
.slide-content-top-left .circle-icon {
  color: #3383f0;
  font-size: 0.8vw;
}
.slide-title {
  font-size: 2vw;
  font-weight: 500;
  margin: 0;
  color: #3383f0;
}

.slide-description {
  font-size: 1.1vw;
  color: #5a5c61;
  letter-spacing: 1px;
  line-height: 1.7;
  word-spacing: 1px;
}

.navigation-buttons {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 8vw;
}

.nav-button {
  background-color: #d6e6fc;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button:hover {
  background-color: #d1d1d1;
}

.icon {
  font-size: 0.1vw !important;
  color: #3383f0;
}

/* Hide default Swiper navigation buttons */
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

@media (max-width: 1200px) {
  .navigation-buttons {
    bottom: 0;
    right: 0;
    width: 8vw;
  }

  .nav-button {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 768px) {
  .slide-content-top-left {
    height: 30px;
    width: 30px;
  }
  .slide-content-top-left .circle-icon {
    color: #3383f0;
    font-size: 1rem;
  }
  .slide-title {
    font-size: 1.6rem;
  }

  .slide-description {
    font-size: 1rem;
    line-height: 1.5;
  }
  .navigation-buttons {
    display: none;
  }
}
