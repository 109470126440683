/* .search-bar{
    width: 70%;
    height: 60px;
    margin: 20px auto;
    background: #000;
} */
/* SearchBar.css */

.search-bar {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 2rem auto;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 50px;
    border: 4px solid white;
    background-color: #f5f7fa;
  }

  .scrolled-search-bar{
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
  
  .search-bar input {
    flex: 1;
    padding: 0 60px;
    height: 100%;
    border: none;
    outline: none;
    font-size: 20px;
    border-radius: 30px 0 0 30px;
    color: #333;
    font-family: "Nunito", sans-serif;
    background-color: #f5f7fa;
  }
  
  .search-bar input::placeholder {
    color: #6b6b6b;
  }
  
  .search-bar button {
    height: 100%; 
    background: linear-gradient(135deg, #005ad2, #0085ff);
    border: none;
    color: white;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 30px 30px 30px 30px;
  }
  
  .search-bar button:hover {
    background: linear-gradient(135deg, #003f8c, #006fd5);
  }
  
  .search-bar button .search-text {
    margin-left: 8px;
    font-size: 16px;
    font-family: "Nunito", sans-serif;
  }
  .search-bar button svg {
    fill: white;
    width: 18px;
    height: 18px;
  }

  /* Responsive Styling */
@media (max-width: 768px) {
    .search-bar {
        width: 90%;
        margin: auto; 
      }
    
      .search-bar input {
        padding: 0 40px; 
        font-size: 18px; 
      }
    
      .search-bar button {
        padding: 0 15px; 
      }
  }
