.team-container{
   width: 100vw;
   & .team-info{
    background-color: white;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}

& .content{
    padding: 20px 30px;
    font-size: 3rem;
    height: 100%;
    
}
}

.team-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    margin-top: 2%;
    justify-content: center;
}

.custom-scrollbar::-webkit-scrollbar{
    width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the scrollbar thumb */
    border-radius: 5px;
    /* Rounded corners for the scrollbar thumb */
}

/* Customize the scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Color of the scrollbar track */
}

/* Customize the scrollbar corner */
.custom-scrollbar::-webkit-scrollbar-corner {
    background-color: transparent;
    /* Color of the scrollbar corner */
}
@media only screen and (max-width: 700px){
    .content{
        font-size: 1rem;
    }
}



