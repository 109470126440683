.navbar {
  display: flex;
  height: 90px;
  width: 100%;
  justify-content: center;
  gap: 7%;
  font-size: 14px;
  font-weight: 800;
  align-items: center;
  margin: auto;
  color: #333333;
  letter-spacing: 1px;
  background-color: transparent;
  position: relative;
  z-index: 9999;
}

.scrolled {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
}

.logo a img {
  height: 10vh;
}

.navbar-menu .dropdown {
  display: none;
}
.show {
  display: block !important;
}
.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  padding-left: 0;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items:hover {
  cursor: pointer;
}

.menu-items a {
  display: flex;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.5rem 0.5rem;
}

.hovering {
  font-weight: 700;
}

.hovering:hover {
  color: #005ad2;
}

.dropdown {
  position: absolute;
  right: 0;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 15rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  transition: all 2s ease-in-out;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.arrowicondown {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
}
.arrowiconforward {
  position: absolute;
  right: 0;
  margin-right: 15px;
}

.cg {
  font-weight: 700;
}
.cg:hover {
  color: #005ad2;
}

.tfr {
  margin-left: 20px;
  border-radius: 24px;
  color: white !important;
  background-color: #005ad2 !important;
  border: 1px solid #005ad2 !important;
  box-shadow: 0 1px 2px rgb(15 15 15 / 10%) !important;
  padding: 10px 21px !important ;
}

.si {
  padding: 10px 21px !important;
  margin-left: 9px;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #232323;
  box-shadow: 0 0 5px #cccccc;
  border: #005ad2 1px solid;
  border-radius: 24px;
}

.menus-mobile {
  width: 100%;
  align-self: center;
  position: absolute;
  top: 80%;
  left: 0;
  height: 100vh;
  background-color: white;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.menus-mobile > li:first-child {
  margin-top: 1rem;
}

.mobile-dropdown {
  width: 60vw;
  list-style: none;
  background-color: #fff;
  display: none;
  transition: all 1s ease-in-out;
  padding-left: 20px;
}

.mobile-dropdown.show {
  display: block;
}

.mobile-cg {
  font-weight: 800;
}

.mobile-tfr {
  border-radius: 24px;
  color: white !important;
  background-color: #005ad2 !important;
  border: 1px solid #005ad2 !important;
  box-shadow: 0 1px 2px rgb(15 15 15 / 10%) !important;
  padding: 8px 12px !important ;
  width: 100px;
}

.mobile-si {
  padding: 8px 12px !important;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: #232323;
  box-shadow: 0 0 5px #cccccc;
  border: #005ad2 1px solid;
  border-radius: 24px;
  width: 100px;
}

.mobile-menu-items {
  position: relative;
}

.mobile-menu-items a {
  display: flex;
  font-size: 14px;
  color: inherit;
  text-decoration: none;
}

.mobile-menu-items > a,
.mobile-menu-items button {
  text-align: left;
  padding: 0.2rem 0;
}
