.testimonial-carousel-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #006eda;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-slide-content {
  display: flex;
  justify-content: space-between;
  background: #006eda;
  color: white;
  width: auto;
  height: 30vw;
  gap: 2rem;
}
.testimonial-slide-content-left {
  flex: 0 0 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial-slide-content-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
  background: #006eda;
}
.testimonial-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.testimonial-slide-content-top {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.testimonial-slide-content-top-left {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.testimonial-slide-content-top-left .testimonial-circle-icon {
  color: #80b7ed;
  font-size: 4vw;
}
.testimonial-slide-title {
  font-size: 2vw;
  font-weight: bold;
  margin: 0;
  color: #80b7ed;
}

.testimonial-slide-description {
  margin-right: 10px;
  font-size: 1.4vw;
  color: rgba(255, 255, 255, 0.808);
}

.testimonial-navigation-buttons {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 8%;
}

.testimonial-nav-button {
  background-color: #d6e6fc;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.testimonial-nav-button:hover {
  background-color: #d1d1d1;
}

.testimonial-icon {
  width: 1vw;
  height: 1vw;
  color: #3383f0;
}
.testimonial-quote-icon {
  color: #80b7ed;
}
.testimonial-slide-name {
  font-size: 2vw;
  letter-spacing: 0.1vw;
  font-weight: 400;
  margin-bottom: 0;
}
.testimonial-slide-designation {
  font-size: 1.6vw;
  letter-spacing: 0.1vw;
  font-weight: 200;
  color: #80b7ed;
  margin: 0;
}
/* Hide default Swiper navigation buttons */
.testimonial-swiper-button-next::after,
.testimonial-swiper-button-prev::after {
  display: none;
}

@media (max-width: 768px) {
  .testimonial-slide-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding: 0;
  }
  .testimonial-slide-content-left,
  .testimonial-slide-content-right {
    flex: 1;
    width: 100%;
  }
  .testimonial-image {
    max-height: 200px;
  }
  .testimonial-slide-title {
    font-size: 5vw;
  }
  .testimonial-slide-description {
    font-size: 3vw;
  }
  .testimonial-slide-name {
    font-size: 4.5vw;
  }
  .testimonial-slide-designation {
    font-size: 3.5vw;
  }
  .testimonial-navigation-buttons {
    width: 22%;
    bottom: 0.5rem;
    right: 1rem;
  }
  .testimonial-nav-button {
    width: 30px;
    height: 30px;
  }
  .testimonial-icon {
    width: 3vw;
    height: 3vw;
  }
}
