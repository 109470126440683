.chat-page {
  display: flex;
  flex-direction: column;
  height: 88vh;
  background-color: #f7f7f8;
  margin-top: 10px;
  position: relative;
}

/* Header Styles */
.chat-header {
  position: fixed;
  top: 90px;
  width: 100%;
  background-color: #f7f7f8;
  border-bottom: 1px solid #e5e5e5;
  z-index: 999;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
}

.chat-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  border: none;
  background: #e61c2d !important;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "Nunito", sans-serif !important;
}

.close-button:hover {
  background-color: #ff0015 !important;
}

.close-icon {
  width: 22px;
  height: 22px;
  color: #ff0000;
}

/* Messages Container Styles */
.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 64px 0 80px 0;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messages-content {
  max-width: 1024px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-group {
  margin-bottom: 24px;
  width: 100%;
  max-width: 800px;
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
  justify-content: center;
  max-width: 100% !important;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
}

.user-avatar {
  background-color: #e5e5e5;
}

.assistant-avatar {
  background-color: #0085ff;
}

.message-bubble {
  margin-left: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  width: calc(100% - 48px);
  max-width: 88%;
  word-wrap: break-word;        
  white-space: pre-wrap;        
  overflow-wrap: break-word;
  font-size: 18px; 
}

.user-bubble {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.assistant-bubble {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}


/* Scrollbar Styles */
.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-track {
  background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

/* Mobile Responsiveness */

@media screen and (min-width: 1200px) {
  .messages-content {
    max-width: 1200px;
  }
  .message-group,
  .message-content {
    max-width: 800px;
  }
  .header-content {
    max-width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .messages-content {
    padding: 0 16px;
    width: 100%;
  }
  
  .message-bubble {
    max-width: 85%;
  }
}

@media screen and (max-width: 480px) {
  .messages-content {
    padding: 0 12px;
  }
  
  .message-bubble {
    max-width: 80%;
    margin-left: 12px;
  }
  
  .avatar {
    width: 28px;
    height: 28px;
  }
}